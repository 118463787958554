import lottieWeb from 'lottie-web';
import { isEditMode } from '@js/utils';

export default class Index {
    constructor(rootNode, props) {
        if (isEditMode()) {
            return;
        }

        this.$refs = {
            rootNode: rootNode,
            lottieContainer: rootNode.querySelector('.lottie-container'),
        };

        const animationData = props.json ? JSON.parse(props.json) : null;

        const animation = lottieWeb.loadAnimation({
            container: this.$refs.lottieContainer,
            path: props.path,
            animationData,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            name: props.name,
        });
    }
}
