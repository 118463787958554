import Swiper from "swiper";

export default class Slider {
    constructor(rootNode, props) {
        this.root = rootNode;
        this.swiper = this.root.querySelector(".swiper");

        this.init();
    }

    init() {
        const paginationElements = this.root.querySelectorAll(".pagination > *");
        if(paginationElements[0]){
            paginationElements[0].classList.add("active");
        }
        
        const swiper = new Swiper(this.swiper, {
            speed: 400,
            spaceBetween: 100,
        });

        swiper.on("slideChange", (i) => {
            paginationElements.forEach((elem, i) => {
                elem.classList.toggle("active", swiper.activeIndex == i);
            });
        });

        paginationElements.forEach((elem, i) => {
            elem.addEventListener("click", () => {
                swiper.slideTo(i, 1000);
            });
        });
    }
}
