import Swiper from "swiper/bundle";

export default class CardSlider {
    constructor(rootNode, props) {
        this.root = rootNode;
        this.initSwiper();
    }

    initSwiper() {
        this.swiper = new Swiper(
            this.root.querySelector(".CardSlider .swiper"),
            {
                speed: 400,
                spaceBetween: 16,
                slidesPerView: "auto",
                pagination: {
                    el: ".swiper-pagination",
                    type: "bullets",
                    clickable: true,
                },
            }
        );
    }
}
