import { breakpoints } from "@js/breakpoints";

export default class Header {
    constructor(rootNode, props) {
        this.root = rootNode;

        this.nav = this.root.querySelector("nav");

        this.breakpoint = breakpoints.lg;

        if (innerWidth > this.breakpoint) this.initSize();

        this.initMenuToggle();
        this.initLanguageToggle();
        this.initScrollListener();
    }

    initSize() {
        const importantLinks = this.root.querySelector(".important-links");
        const importantLinksLeft = importantLinks.getBoundingClientRect().left;

        const screenWidth = innerWidth;
        const navPaddingLeft = 80;
        const offset = 16;

        const width =
            screenWidth - importantLinksLeft + navPaddingLeft - offset;
        this.nav.style.setProperty("width", `${width}px`);
    }

    initLanguageToggle(){
        const toggle = this.root.querySelector('#toggle-languages');

        if(toggle){
            toggle.addEventListener("click", () => {
                toggle.classList.toggle("open");
            });
        }
    }

    initMenuToggle() {
        const toggleMenu = this.root.querySelector("#toggle-menu");
        const main = document.querySelector("main");

        toggleMenu.addEventListener("click", () => {
            // set max-height of nav in order to be scrollable at small screen heights
            if (innerWidth < this.breakpoint) this.setNavHeight();

            this.nav.classList.toggle("open");
            toggleMenu.classList.toggle("open");

            if (innerWidth > this.breakpoint) {
                main.classList.toggle("move");
            }
        });
    }

    initScrollListener() {
        setInterval(() => {
            if (scrollY > 0) {
                this.root.classList.add("scroll");
            } else {
                this.root.classList.remove("scroll");
            }
        }, 100);
    }

    setNavHeight() {
        const screenHeight = innerHeight;
        const headerHeight = this.root.getBoundingClientRect().height;

        this.nav.style.setProperty(
            "max-height",
            `${screenHeight - headerHeight}px`
        );
    }
}
