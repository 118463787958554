export default class Accordion {
    constructor(rootNode, props) {
        this.root = rootNode;

        this.init();
    }

    init() {
        const buttons = this.root.querySelectorAll(".toggle-collapse");

        buttons.forEach((button, i) => {
            let open = false;

            const collapse = button.nextElementSibling;
            const collapseHeight = collapse.getBoundingClientRect().height;

            collapse.style.setProperty("max-height", 0);

            button.addEventListener("click", () => {
                open = !open;
                collapse.classList.toggle("open");
                button.classList.toggle("active");
                collapse.style.maxHeight = open ? `${collapseHeight}px` : 0;
            });
        });
    }
}
